import { useSelector } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

import {
  t,
  selectors,
  toLocaleDateString,
  getPredefinedRanges,
  toLocalDate,
} from '@formue-app/core';

import { ParagraphSmall } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import { printCondition } from '../../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    display: none;
  }
`;

const {
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const DataVerificationNotice = (props) => {
  const { useBaseSection = false } = props;
  const activeFilters = useSelector(activeFiltersSelector);

  let endDate = toLocalDate(new Date(activeFilters?.endDate));

  if (!activeFilters?.endDate) {
    const { newEndDate } = getPredefinedRanges(activeFilters?.period);
    endDate = toLocalDate(new Date(newEndDate));
  }

  let currentDate = toLocalDate(new Date());
  let notificationDate;

  if (currentDate.getDate() >= 10) {
    notificationDate = toLocalDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
    );
  } else {
    notificationDate = toLocalDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 0)
    );
  }

  if (endDate <= notificationDate) {
    return null;
  }

  if (useBaseSection) {
    return (
      <StyledBaseSection columns={12}>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:dataVerificationNotice', {
            endDate: toLocaleDateString(notificationDate),
          })}
        </ParagraphSmall>
      </StyledBaseSection>
    );
  } else {
    return (
      <ParagraphSmall>
        {t('portfolio:periodicReporting:dataVerificationNotice', {
          endDate: toLocaleDateString(notificationDate),
        })}
      </ParagraphSmall>
    );
  }
};
