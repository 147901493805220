import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  dismissedAppPromotion: false,
};

export const reducer = handleActions(
  {
    [actions.setDismissedAppPromotion]: (state, { payload }) => ({
      ...state,
      dismissedAppPromotion: Boolean(payload),
    }),
  },
  defaultState
);
