import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  usePortfolioRelationshipFilter,
  usePortfolioTaxClassFilter,
  usePortfolioInvestmentAccountsFilter,
  usePortfolioExternalReportingFilter,
  useStartegyOptions,
  selectors,
  getPredefinedRanges,
} from '@formue-app/core';

export const useShowStrategy = () => {
  const relationshipFilter = usePortfolioRelationshipFilter();
  const taxClassesFilter = usePortfolioTaxClassFilter();
  const investmentAccountsFilter = usePortfolioInvestmentAccountsFilter();
  const externalreportingFilter = usePortfolioExternalReportingFilter();
  const allStrategies = useStartegyOptions(true);
  const activeStrategyId = useSelector(
    selectors.ui.portfolio.activeStrategySelector
  );
  const { period, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );
  const { newEndDate } = getPredefinedRanges(period);

  return useMemo(() => {
    if (!activeStrategyId) {
      return false;
    }

    const now = new Date();
    const strategy = allStrategies.find(
      (_strategy) => _strategy.id === activeStrategyId
    );

    if (!strategy) return false;

    const strategyAccountsSelected = relationshipFilter
      .filter((item) => item.selected)
      .map((item) => item.ssid)
      .every((r) => strategy?.accounts.includes(r));

    const strategyTaxClassesSelected = taxClassesFilter
      .filter((item) => item.selected)
      .map((item) => item.name)
      .every((t) => strategy.taxClasses.includes(t));

    const excludedInvestmentAccounts = investmentAccountsFilter
      .filter((item) => item.selected && item.status !== 'closed')
      .map((item) => item.name)
      .every((a) => strategy.portfolioAccounts.includes(a));

    const externalFiltersSelected = externalreportingFilter
      .filter((item) => item.selected)
      .map((item) => item.name)
      .every((e) =>
        strategy?.externalReporting
          ?.map((item) => {
            switch (item) {
              case 'Formue Depot':
                return 'showInternalPortfolio';
              case 'Ekstern':
                return 'showExternalPortfolio';
              default:
                return null;
            }
          })
          .filter(Boolean)
          .includes(e)
      );

    const isCurrentMonth =
      now.getFullYear() === new Date(endDate || newEndDate).getFullYear() &&
      now.getMonth() === new Date(endDate || newEndDate).getMonth();

    return (
      strategyAccountsSelected &&
      strategyTaxClassesSelected &&
      excludedInvestmentAccounts &&
      externalFiltersSelected &&
      allStrategies.length &&
      isCurrentMonth
    );
  }, [
    relationshipFilter,
    taxClassesFilter,
    investmentAccountsFilter,
    externalreportingFilter,
    allStrategies,
    activeStrategyId,
    endDate,
    newEndDate,
  ]);
};
