const authMethods = process.env.REACT_APP_AUTH_METHODS.split(',');

const envNumber = value => {
  try {
    return parseInt(value, 10);
  } catch (err) {
    return undefined;
  }
};

export const config = {
  title: process.env.REACT_APP_NAME,
  countryCode: process.env.REACT_APP_COUNTRY_CODE,
  domain: process.env.REACT_APP_DOMAIN,
  dev: process.env.NODE_ENV === 'development' || false,
  translations: {
    lookupKey: process.env.REACT_APP_TRANSLATION_LOOKUP_KEY,
  },
  app: {
    appVersion: process.env.REACT_APP_VERSION,
    platform: 'Web',
    devicePlatform: navigator.platform,
    bundleId: 'no.formue.formue.no',
    urlScheme: process.env.REACT_APP_PROTOCOL,
    authCallbackUrl: `${process.env.REACT_APP_DOMAIN}/auth/callback`,
    authMethods,
  },
  api: {
    host: process.env.REACT_APP_API_HOST,
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    client: {
      user: process.env.REACT_APP_API_CLIENT_USER,
      pass: process.env.REACT_APP_API_CLIENT_PASS,
    },
  },
  auth: {
    timeout: envNumber(process.env.REACT_APP_AUTH_TIMEOUT) || 1000*60*30,
    sso: {
      azureAd: {
        clientId: '9ed11926-747e-4b34-a1c3-b2192856f7c4',
        tenant: '6d8e40a8-45db-45c1-881f-e2de77f3f80a',
        redirectUri: `https://${process.env.REACT_APP_DOMAIN}/auth/sso`,
      }
    },
    usernameExchange: {
      password: process.env.REACT_APP_USERNAME_EXCHANGE_PASSWORD,
    }
  },
  qlik: {
    host: process.env.REACT_APP_QAP_HOST,
    appId: process.env.REACT_APP_QAP_APP_ID,
  },
  ga: {
    enabled: process.env.REACT_APP_GA_TRACKING_ENABLED,
    code: process.env.REACT_APP_GA_TRACKING_CODE,
  },
  blog: {
    host: process.env.REACT_APP_WORDPRESS_HOST,
  },
  pardot: {
    contactFormUrl: process.env.REACT_APP_PARDOT_CONTACT_FORM_URL,
  },
};
