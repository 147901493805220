import React from 'react';
import styled from 'styled-components';

import { printCondition } from '../../constants/media';

const Print = styled.div`
  @media ${printCondition} {
    nav,
    .toggle {
      display: none;
    }

    @page {
      margin: 8mm 0.1mm 8mm 0.1mm !important;

      counter-increment: pageNumber;
      @bottom-right-corner {
        content: counter(pageNumber);
      }
    }

    body {
      padding: 0 !important;
      margin: 0 !important;
    }

    a[href^="http://"]:after,
    a[href^="https://"]:after
    {
      content: ' (' attr(href) ')';
      font-size: 90%;
    }
  }
`;

export const PrintDefault = (props) => {
  const { children } = props;

  return <Print>{children}</Print>;
};
