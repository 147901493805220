import { call, put, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { assetClassBenchmarkIndices } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { transformActiveFilters, waitFor } from '../../../services';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'asset-class-benchmark-indices'
);

// Create the sagas for this entity
function* readAssetClassBenchmarkIndices() {
  yield takeLatest(actions.indexRequestBegin, function* () {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
    const args = transformActiveFilters(activeFilters);

    try {
      const result = yield call(assetClassBenchmarkIndices.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readAssetClassBenchmarkIndices, ...resourceSagas];
