import { createSelector } from 'reselect';

import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  portfolioIndicesAllIds,
  portfolioIndicesById,
  allPortfolioIndicesSelector,
  portfolioIndicesMetadataSelector,
] = resourceSelectors('portfolioIndices');
