import { combineReducers } from 'redux';

import * as navigation from './navigation';
import * as document from './document';
import * as clientSearch from './clientSearch';
import * as podcast from './podcast';
import * as passkeys from './passkeys';
import * as appPromotion from './appPromotion';

const all = {
  navigation: navigation.reducer,
  document: document.reducer,
  clientSearch: clientSearch.reducer,
  podcast: podcast.reducer,
  passkeys: passkeys.reducer,
  appPromotion: appPromotion.reducer,
};
const combined = combineReducers(all);

export const reducers = {
  all,
  combined,
};
