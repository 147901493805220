import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { useQlikBookmarks } from '@formue-app/core';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_20,
} from '../../../constants/spacing';

import { accent, backgroundWhite } from '../../../constants/colors';
import { longAnimationTime } from '../../../constants/animation';
import { H3 } from '../../texts';
import { FlipArrow } from '../../common/FlipArrow';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import { SpriteIcon } from '../../common/SpriteIcon';
import { useClickOutside } from '../../../services/hooks';

import { FilterSets } from './FilterSets';
import { ExtendedReportingFilterGroup } from './ExtendedReportingFilterGroup';
import { ExtendedReportingPeriodRangeFilter } from './ExtendedReportingPeriodRangeFilter';
import { ExtendedReportingPeriodsFilter } from './ExtendedReportingPeriodsFilter';

import { ReactComponent as IconDots } from '../../../assets/icons/extended-reporting/icon-dots.svg';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';

const Container = styled.div`
  width: 300px;
  position: absolute;
  right: ${SPACING_24};
  top: 140px;
  min-height: 250px;
  background-color: ${backgroundWhite};
  translate: 100%;
  border-radius: ${BORDER_RADIUS_LARGE};
  opacity: 0;
  transition: all ${longAnimationTime};
  max-height: calc(100vh - 170px);
  overflow-y: scroll;

  ${(props) =>
    props.open &&
    `
    translate: 0;
    opacity: 1;
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING_16};
  padding-bottom: 0;
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDotsButton = styled(IconDots)`
  margin-right: ${SPACING_16};
  cursor: pointer;
`;

const StyledCloseButton = styled(SpriteIconFunctional)`
  margin-right: ${SPACING_16};
  position: relative;
  right: 6px;
  cursor: pointer;
`;

const InnerContainer = styled.div`
  padding: ${SPACING_16};
`;

const StyledFilterSets = styled(FilterSets)`
  position: absolute;
  top: 50px;
  left: 25px;
  width: 218px;
  z-index: 10;
`;

const PORTFOLIO_COMPONENT = {
  label: 'Portfolio component',
  fields: [
    { name: 'CustomerName', label: 'Customer' },
    { name: 'Managed by', label: 'Managed by' },
    { name: 'Portfolio function', label: 'Portfolio function' },
    { name: 'Asset class', label: 'Asset class' },
    { name: 'CustodianName', label: 'Custodian' },
    { name: 'Security', label: 'Security' },
    { name: 'Continent', label: 'Continent' },
    { name: 'Country', label: 'Country' },
    { name: 'Sector', label: 'Sector' },
  ],
};

export const ExtendedReportingFilters = ({
  app,
  selections,
  activeFilters,
  onClear = () => {},
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterSetsOpen, setFilterSetsOpen] = useState(false);

  const [bookmarks, _, applyBookmark, removeBookmark] = useQlikBookmarks(app);

  const innerContainerRef = useRef();

  useClickOutside(innerContainerRef, () => {
    if (filterSetsOpen) {
      setFilterSetsOpen(false);
    }
  });

  const renderFilterSetsButton = () => {
    // If there are no "bookmarks" saved, don't show the filter sets button
    if (!bookmarks.length) return null;

    // Render the "dots button" or "close button"
    return filterSetsOpen ? (
      <StyledCloseButton
        id="Close"
        size={12}
        onClick={() => {
          setFilterSetsOpen(false);
        }}
      />
    ) : (
      <StyledDotsButton
        onClick={() => {
          setFilterSetsOpen(true);
        }}
      />
    );
  };

  return (
    <Container {...rest}>
      <Header>
        <H3>Filter</H3>
        <HeaderButtons>
          {renderFilterSetsButton()}

          <FlipArrow
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            direction={isExpanded ? 'up' : 'down'}
            backgroundColor="transparent"
            color={accent.ocean490}
            style={{ cursor: 'pointer', backgroundColor: accent.sand1 }}
          />
        </HeaderButtons>
      </Header>

      <InnerContainer ref={innerContainerRef}>
        <StyledFilterSets
          filterSets={bookmarks}
          remove={removeBookmark}
          apply={applyBookmark}
          style={filterSetsOpen ? { display: 'block' } : { display: 'none' }}
        />

        <ExtendedReportingFilterGroup
          title={PORTFOLIO_COMPONENT.label}
          fields={PORTFOLIO_COMPONENT.fields}
          icon={<SpriteIcon id="Portfolio" size={SPACING_20} />}
          style={{ marginBottom: 9 }}
          startExpanded={isExpanded}
          app={app}
          selections={selections}
        />

        <ExtendedReportingFilterGroup
          title={'Time'}
          icon={<SpriteIcon id="Time" size={SPACING_20} />}
          style={{ marginBottom: 9 }}
          startExpanded={isExpanded}
          app={app}
          selections={selections}
        >
          <div>
            <ExtendedReportingPeriodsFilter
              app={app}
              selections={selections}
              activeFilters={activeFilters}
            />
            <HorizontalDivider style={{ margin: `0 ${SPACING_8}` }} />
          </div>
          <div>
            <ExtendedReportingPeriodRangeFilter
              app={app}
              selections={selections}
            />
            <HorizontalDivider style={{ margin: `0 ${SPACING_8}` }} />
          </div>
        </ExtendedReportingFilterGroup>
      </InnerContainer>
    </Container>
  );
};
