import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import {
  formatBigMoney,
  t,
  useResource,
  selectors,
  percentage,
  transformActiveFilters,
  financialValues,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_32, SPACING_8 } from '../../../constants/spacing';
import { H2, H5, ParagraphSmall } from '../../texts';
import { WaterfallChart } from '../../graphs/WaterfallChart';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { accent } from '../../../constants/colors';
import {
  H6LineHeight,
  H6Size,
  paragraphXSmallLineHeight,
  paragraphXSmallSize,
} from '../../../constants/text';
import { printCondition } from '../../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  height: 368px;

  @media ${printCondition} {
    margin-left: ${SPACING_24} !important;
    gap: ${SPACING_8};
    height: 250px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_32};

  div {
    display: flex;
    flex-direction: column;
    gap: ${SPACING_8};
  }
`;

const StyledWaterfallChart = styled(WaterfallChart)`
  h6 {
    font-size: ${paragraphXSmallSize};
    line-height: ${paragraphXSmallLineHeight};
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    word-break: auto-phrase;

    width: 80%;
    margin-left: calc(-23px / 2);
  }

  h3 {
    font-size: ${H6Size};
    line-height: ${H6LineHeight};
    font-weight: 500;
    text-transform: none;
  }
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const PeriodicChange = (props) => {
  const loading = useResource(['PORTFOLIOS/INDEX']);
  const portfolioTotal = useSelector(portfolioTotalSelector);

  const activeFilters = useSelector(activeFiltersSelector);
  const prevActiveFilters = useRef(null);

  const [localLoading, setLocalLoading] = useState(false);
  const [financialValuesData, setFinancialValuesData] = useState([]);

  // Since this data is detached from the time filter we fetch the data manually to
  // not clutter the store with data that is not in line with the active filters of the user.
  // As we naivly assume that the store contains what you need based on the active filters.
  //
  // If we end up needing this type of detached data in the feature extracting this into a seperate
  // function would be good.
  useEffect(() => {
    const fetchData = async () => {
      setLocalLoading(true);
      let args = transformActiveFilters(activeFilters);
      args = {
        ...args,
        granularity: 'MONTH',
      };
      const data = await financialValues.get(args);
      const formatedData = data.data.map((item) => {
        return {
          id: item.id,
          type: item.type,
          ...item.attributes,
        };
      });

      setFinancialValuesData(formatedData);
      setLocalLoading(false);
    };

    if (!isEqual(activeFilters, prevActiveFilters.current)) {
      prevActiveFilters.current = activeFilters;
      fetchData().catch(console.error);
    }
  }, [activeFilters]);

  const startPeriodData = financialValuesData[0];
  const currentPeriodData = financialValuesData[financialValuesData.length - 1];

  const totalGain = financialValuesData.reduce((acc, curr) => {
    return acc + curr?.gain;
  }, 0);
  const totalInOut = financialValuesData.reduce((acc, curr) => {
    return acc + (curr?.deposits + curr?.withdrawals);
  }, 0);

  const startMv =
    startPeriodData?.mv -
    startPeriodData?.gain -
    startPeriodData?.deposits -
    startPeriodData?.withdrawals;

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <div>
          <ParagraphSmall>
            {t('portfolio:periodicReporting:valueChange')}
          </ParagraphSmall>
          <H2>{formatBigMoney(currentPeriodData?.mv, false)}</H2>
        </div>
        <div>
          <ParagraphSmall>
            {t('portfolio:periodicReporting:returnForPeriod')}
          </ParagraphSmall>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <H2>{formatBigMoney(portfolioTotal?.gain, true)}</H2>
            <H5 style={{ paddingBottom: 6 }}>
              ({percentage(portfolioTotal?.twr * 100)})
            </H5>
          </div>
        </div>
      </TextWrapper>

      {localLoading ? (
        <CenteredActivityIndicator />
      ) : (
        <StyledWaterfallChart
          data={[
            {
              label: t(
                'presentation:annualReview:portfolioDevelopment:startValue'
              ),
              value: startMv,
              color: accent.ocean230,
            },
            {
              label: t(
                'presentation:annualReview:portfolioDevelopment:depositsWithdrawals'
              ),
              value: totalInOut,
              color: accent.ocean1,
            },
            {
              label: t('presentation:annualReview:portfolioDevelopment:return'),
              value: totalGain,
              color: accent.ocean370,
            },
          ]}
          total={{
            label: t('presentation:annualReview:portfolioDevelopment:endValue'),
            value: currentPeriodData?.mv,
            color: accent.ocean230,
          }}
          textColor={accent.ocean490}
          lineColor={accent.sand1}
        />
      )}
    </StyledBaseSection>
  );
};
