import { LANGUAGE_TO_LANGUAGE_HEADER } from '../localization/languageCodes';
import { i18n, t } from '../localization';

export const toLocaleDateString = (
  date,
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
) => {
  if (!date) {
    return '-';
  }
  try {
    const locale = LANGUAGE_TO_LANGUAGE_HEADER[i18n.locale];
    return new Date(date).toLocaleDateString(locale, options);
  } catch (e) {
    return new Date(date).toLocaleDateString(options);
  }
};

const daysBetween = (date1, date2) => {
  const timeDiff = date2.getTime() - date1.getTime();
  var days = timeDiff / (1000 * 3600 * 24);
  return Math.floor(days);
};

export const humanFriendlyDate = (date, includeTimestamp) => {
  const today = new Date();
  const theDate = new Date(date);
  today.setHours(0, 0, 0, 0);

  const dayDiff = daysBetween(today, theDate);
  const absDayDiff = Math.abs(dayDiff);

  const isToday = dayDiff === 0;

  const isYesterday = dayDiff === -1;
  const isLessThan7DaysAgo = dayDiff < 1 && absDayDiff < 7;

  const isTomorrow = dayDiff === 1;
  const isLessThan7DaysUntil = dayDiff > 1 && dayDiff < 7;

  var time = theDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const timepart = includeTimestamp ? ` ${time}` : '';

  if (isToday) {
    return `${t('globals:fromDate:today')}${timepart}`;
  }
  if (isYesterday) {
    return `${t('globals:fromDate:yesterday')}${timepart}`;
  }
  if (isTomorrow) {
    return `${t('globals:fromDate:tomorrow')}${timepart}`;
  }
  if (isLessThan7DaysAgo) {
    return `${t('globals:fromDate:daysAgo', { days: absDayDiff })}${timepart}`;
  }
  if (isLessThan7DaysUntil) {
    return `${t('globals:fromDate:daysUntil', {
      days: absDayDiff,
    })}${timepart}`;
  }
  return `${toLocaleDateString(theDate)}${timepart}`;
};

export const getLocalizedMonthName = (
  monthNumber,
  long,
  capitalize = false
) => {
  let objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(monthNumber);

  let month = toLocaleDateString(objDate, { month: long ? 'long' : 'short' });

  if (capitalize) {
    month = month.charAt(0).toUpperCase() + month.slice(1);
  }

  return month;
};

export const addMonths = (date, months) => {
  var d = date.getDate();
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + +months);
  if (newDate.getDate() != d) {
    newDate.setDate(0);
  }
  return newDate;
};

export const isSameYearAndMonth = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
  );
};

/**
 * Converts a period string to a JS Date.
 * We get period strings from the API in a "YYYYMM" format.
 * @param {string} period
 */

export const periodToDate = (period) => {
  const date = new Date(
    period.toString().substring(0, 4),
    parseInt(period.toString().substring(4), 10),
    0
  );
  return date;
};

export const toLocalDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const MONTHS = Array(12)
  .fill('')
  .map((_, i) => getLocalizedMonthName(i, true));
