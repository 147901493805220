import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  formatBigMoney,
  formatHexOpacity,
  formatTwrTicks,
  lightenColor,
  percentage,
} from '@formue-app/core';

import { accent, backgroundWhite } from '../../../constants/colors';
import { bodyFontStack } from '../../../fonts';

export const PortfolioReportingChartTooltip = ({
  x,
  y,
  datum,
  width,
  height,
  dx,
  dy,
  color = accent.ocean3,
  backgroundColor = accent.ocean4,
  lineColor = accent.ocean3,
  dataExtractor,
  indexData,
  indexColorSet,
}) => {
  const portfolio = useSelector((state) => state.ui.portfolio);
  const { calculatedAt } = portfolio;

  let indexPresent = false;

  indexData.forEach((item) => {
    const index = item.index.find((index) => index.period === datum.p);

    if (index) {
      indexPresent = true;
    }
  });

  const rectangleWidth = 80;
  const rectangleHeight = 40;
  const indexRowHeight = 18;
  const indexRowsTotalHeight = indexRowHeight * indexData.length;
  const indexRectangleHeight = rectangleHeight + indexRowsTotalHeight;
  let date = moment(datum.x, 'YYYYMM');
  let xMax = x;
  let yMax = y;

  // We get the dates as the last days of the month.
  // For example if today is: 05052020 the input will be 31052020.
  // If that's the case, show the "calculatedAt" day instead.
  // Basically, the last date displayed
  // in the tooltip should be the last calculated value.
  if (date > moment(calculatedAt)) date = moment(calculatedAt);

  // If we want to keep the tooltip rectangle within the dimensions of the chart we need
  // to limit the xMax / yMax positions. On the x-axis it needs to be done on both sides.
  // On y-axis it needs to be done only on top due to the default position of the
  // tooltip (always on top)
  if (x + rectangleWidth > width) xMax = width - rectangleWidth / 2;
  if (x - rectangleWidth < 0) xMax = rectangleWidth / 2;
  if (indexPresent) {
    if (y - (50 + indexRowsTotalHeight) < 0) yMax = 50 + indexRowsTotalHeight;
  } else {
    if (y - 50 < 0) yMax = 50;
  }

  return (
    <g>
      <line
        x1={x}
        y1="0"
        x2={x}
        y2="300"
        stroke={lineColor}
        strokeWidth={0.5}
      />
      <rect
        x={xMax - rectangleWidth / 2}
        y={
          yMax -
          rectangleHeight -
          10 -
          (indexPresent ? indexRowsTotalHeight : 0)
        }
        width={rectangleWidth}
        dx={dx}
        dy={dy}
        height={indexPresent ? indexRectangleHeight : rectangleHeight}
        rx="3"
        fill={formatHexOpacity(backgroundColor, 0.8)}
        stroke={lightenColor(lineColor, 0.2)}
        strokeWidth={0.5}
      />
      {indexPresent ? (
        <circle
          cx={xMax + 30}
          cy={yMax - (37 + indexRowsTotalHeight)}
          r="4"
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      ) : null}
      <text
        x={xMax - (indexPresent ? 32 : 0)}
        y={yMax - (indexPresent ? 32 + indexRowsTotalHeight : 32)}
        width={rectangleWidth}
        textAnchor={indexPresent ? 'right' : 'middle'}
        fontSize="14"
        fill={backgroundWhite}
        fontFamily={bodyFontStack}
      >
        {dataExtractor === 'mv'
          ? formatBigMoney(datum.mv, false)
          : formatTwrTicks(datum.twr, 1, true)}
      </text>
      {indexData.map((item, i) => {
        const index = item.index.find((index) => index.period === datum.p);

        if (!index) return null;

        return (
          <>
            <circle
              cx={xMax + 30}
              cy={yMax - 38 - indexRowHeight * i}
              r="4"
              fill={indexColorSet[i % indexColorSet.length]}
              stroke="#fff"
              strokeWidth={1}
            />
            <text
              x={xMax - 18}
              y={yMax - 34 - indexRowHeight * i}
              width={rectangleWidth}
              textAnchor="right"
              fontSize="10"
              fill={backgroundWhite}
              fontFamily={bodyFontStack}
            >
              ({percentage(index.rate * 100, 1, 1, true, true)})
            </text>
          </>
        );
      })}
      <text
        x={xMax}
        y={yMax - 18}
        width={rectangleWidth}
        textAnchor="middle"
        fontSize="10"
        fill={backgroundWhite}
        fontWeight={100}
        fontFamily={bodyFontStack}
      >
        {date.format('DD.MM.YYYY')}
      </text>

      <circle
        cx={x}
        cy={y}
        r="4"
        fill={color}
        stroke="#fff"
        strokeWidth={0.5}
      />
    </g>
  );
};
