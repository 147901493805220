import {
  take,
  put,
  takeLatest,
  takeEvery,
  select,
  call,
} from 'redux-saga/effects';

import { AsyncStorage } from '../../../services/AsyncStorage';

import { actions as entityActions } from '../../entities';
import { selectors as entitySelector } from '../../entities';
import { actions } from './actions';
import { activeFiltersSelector } from './selectors';
import { operatingCountrySelector } from '../../auth/selectors';
import {
  accessibleAccountsSelector,
  currentUserSelector,
} from '../user/selectors';

export const DEFAULT_PORTFOLIO_FILTERS_STORAGE_KEY =
  'defaultPortfolioFiltersV2';

const defaultFiltersSelector = (state) => state.ui.portfolio.defaultFilters;

const {
  users: usersActions,
  marketValues: marketValuesActions,
  financialValues: financialValuesActions,
  filterValues: filterValuesActions,
  portfolios: portfoliosActions,
  transactions: transactionsActions,
  portfolioLenses: portfolioLensesActions,
  portfolioIndices: portfolioIndicesAction,
  periodicIndices: periodicIndicesAction,
  standardFilters: standardFiltersActions,
  securities: securitiesActions,
  strategy: strategyActions,
  securityPerformance: securityPerformanceActions,
  funds: fundsActions,
  illiquidCashflow: illiquidCashflowActions,
  illiquidSecurities: illiquidSecuritiesActions,
  assetClassBenchmarkIndices: assetClassBenchmarkIndicesActions,
} = entityActions;

const {
  strategy: { allStrategiesSelector },
} = entitySelector;

/**
 * Initial portfolio loading requires a few thing to be in the
 * the store before we can load. We need the accessible accounts
 * on the user, and we need to have loaded the default filters
 *
 * To enable that we utilize a neat function for redux sagas
 * that enables us to halt a running saga until we see an event
 * being dispatched.
 */
function* init() {
  while (true) {
    // One can optionally pass in an ssid as the payload data for this action
    // this is done in case of impersonation, so we now which user to fetch
    // portfolio data for.
    const { payload: ssid } = yield take(actions.init);

    // Make sure we have loaded the user before continuing. If we are
    // impersonating we need to make sure we are loading the correct user
    // so we can't naivly just listen for success, we need to check the
    // user id.
    let userLoaded = false;
    while (!userLoaded) {
      const { payload } = yield take(usersActions.showRequestSuccess);
      // If we didn't get an ssid as the payload, we don't need to check which
      // user is loaded, just that any user is loaded
      if (!ssid) {
        userLoaded = true;
      }

      // Match loaded user with ssid passed into action
      if (ssid && payload.result.users[0] === ssid) {
        userLoaded = true;
      }
    }

    // If this is a swedish user we need to fetch filter values from the API as
    // well. These are used generate the portfolio filters, so need to be
    // loaded when we initialize the portfolio.
    const currentUser = yield select(currentUserSelector);
    if (['se', 'dk'].includes(currentUser.operatingCountry)) {
      const accessibleAccounts = yield select(accessibleAccountsSelector);
      const ssids = accessibleAccounts.map((a) => a.ssid);
      yield put(filterValuesActions.indexRequestBegin({ ssids }));

      let filterValuesLoaded = false;
      while (!filterValuesLoaded) {
        yield take(filterValuesActions.indexRequestSuccess);
        filterValuesLoaded = true;
      }
    }

    // We know that if we get an SSID as input to this action we are impersonating,
    // and when we are impersonating we _don'_t want to load default filters since
    // that would cause wierd side effects by applying default filters for a different
    // user than what is was created for in the first place.
    if (!ssid) {
      // Trigger loading of default filters
      yield put(actions.defaultFiltersLoad());

      // Wait until default filters are loaded
      yield take(actions.defaultFiltersLoaded);
    }

    yield put(actions.initialized());
  }
}

function* handlePortfolioFilterChange() {
  yield takeLatest(
    [
      actions.setFilters,
      actions.filterPeriod,
      actions.filterSsids,
      actions.filterExternalReporting,
      actions.filterTaxClass,
      actions.filterInvestmentAccount,
    ],
    function* () {
      const activeFilters = yield select(activeFiltersSelector);

      // Just flush the resources and the rest will happen on the
      // individual "resource saga" level. However, we want to make sure
      // the ssids are loaded before we trigger filtering. The portfolio
      // ui code relies on the store only holding data that is relevant
      // to the current filters selected. So to prevent us from showing
      // bad data, we need to flush everything when changing filters.
      if (activeFilters?.ssids?.length > 0) {
        yield put(transactionsActions.flush());
        yield put(portfoliosActions.flush());
        yield put(marketValuesActions.flush());
        yield put(securitiesActions.flush());
        yield put(securityPerformanceActions.flush());
        yield put(fundsActions.flush());
        yield put(financialValuesActions.flush());
        yield put(portfolioLensesActions.flush());
        yield put(portfolioIndicesAction.flush());
        yield put(periodicIndicesAction.flush());
        yield put(assetClassBenchmarkIndicesActions.flush());
      }
    }
  );
}

/**
 * Gets the current set of filters applied and stores them with AsyncStorage
 */
function* loadDefaultFilters() {
  yield takeLatest(actions.defaultFiltersLoad, function* ({}) {
    const impersonating = yield select(
      (state) => state.ui.user.isImpersonating
    );
    if (impersonating) {
      yield put(actions.defaultFiltersLoaded(null));
    } else {
      const defaultFiltersStringified = yield call(
        AsyncStorage.getItem,
        DEFAULT_PORTFOLIO_FILTERS_STORAGE_KEY
      );
      const defaultFilters = JSON.parse(defaultFiltersStringified);

      // We store default filters locally, and that includes a list of account ssids users want to exclude
      // if there is ssids in this locally stored list that we don't currently have access to, e.g
      // accessible accounts have changed since they stored the filters wierd bugs might happen since
      // we are trying to use ssids in filters that they don't have access to. Shouldn't be a security
      // issue since the ssids are used for _exclusion_ not _inclusion_, but we do have some logic
      // that compares the number excludes ssids to accessible accounts etc, and if we have bad
      // data in here that causes issue.
      //
      // So to prevent that, whenever we load default filters, we should clean out any ssids that
      // the user don't have access to anymore.
      const accessibleAccounts = yield select(accessibleAccountsSelector);
      const accessibleAccountIds = accessibleAccounts.map((a) => a.ssid);
      const excludedSsids = defaultFilters?.excludedSsids;
      const cleanedExcludedSsids = excludedSsids?.filter((excludedSsid) =>
        accessibleAccountIds.includes(excludedSsid)
      );

      // If we have a cleaned list of excluded ssids, we want to override what
      // is passed into defaultFiltersLoaded, but only if we actually have some
      // default filters stored.
      const modifiedDefaultFilters = defaultFilters;

      // To prevent a bug where startDate and endDate was set even when saving predefined periods
      // we prioritise startDate and endDate over period if they are present. This causes a bug where
      // startDate and endDates are saved in default filter will drift over time. This fix makes it so
      // that we only use startDate and endDate if period is set to CUSTOM
      if (modifiedDefaultFilters) {
        try {
          if (modifiedDefaultFilters?.period !== 'CUSTOM') {
            modifiedDefaultFilters?.startDate &&
              delete modifiedDefaultFilters.startDate;
            modifiedDefaultFilters?.endDate &&
              delete modifiedDefaultFilters.endDate;
          }
        } catch (_) {
          // we don't care about errors here.
        }
      }

      if (defaultFilters && cleanedExcludedSsids) {
        modifiedDefaultFilters.excludedSsids = cleanedExcludedSsids;
      }

      yield put(actions.defaultFiltersLoaded(modifiedDefaultFilters));

      // Update default filters for this user if we had to clean the excluded ssids
      if (
        cleanedExcludedSsids &&
        cleanedExcludedSsids.length !== excludedSsids.length
      ) {
        yield put(actions.defaultFiltersSave());
      }
    }
  });
}

/**
 * Gets the current set of filters applied and stores them with AsyncStorage
 */
function* saveDefaultFilters() {
  yield takeLatest(actions.defaultFiltersSave, function* ({}) {
    const defaultFilters = yield select(defaultFiltersSelector);
    const stringified = JSON.stringify(defaultFilters);

    yield put(
      standardFiltersActions.updateRequestBegin({ data: defaultFilters })
    );
    yield call(
      AsyncStorage.setItem,
      DEFAULT_PORTFOLIO_FILTERS_STORAGE_KEY,
      stringified
    );
  });
}

function* deleteDefaultFilters() {
  yield takeLatest(actions.defaultFiltersDelete, function* ({}) {
    yield put(standardFiltersActions.destroyRequestBegin());
    yield call(AsyncStorage.removeItem, DEFAULT_PORTFOLIO_FILTERS_STORAGE_KEY);
  });
}

function* setActiveStrategy() {
  yield takeLatest(strategyActions.indexRequestSuccess, function* ({}) {
    const allStrategies = yield select(allStrategiesSelector);

    // Here we can implement logic to show the best suted strategy on load
    yield put(actions.activeStrategy(allStrategies[0]?.id));
  });
}

function* onActiveStrategyChange() {
  yield takeLatest(actions.activeStrategy, function* ({ payload }) {
    const strategies = yield select((state) => state.entities.strategy.byId);
    const strategy = strategies[payload];

    // TODO: Look at preventing loading on first page load
    if (strategy) {
      yield put(
        illiquidCashflowActions.indexRequestBegin({
          strategy: strategy.id,
          ssids: strategy.accounts,
        })
      );
      yield put(
        illiquidSecuritiesActions.indexRequestBegin({
          ssids: strategy.accounts,
        })
      );
    }
  });
}

// Reset filters when app is calling flush (usally happens when switching clients)
function* flush() {
  yield takeEvery('APP/FLUSH', function* () {
    yield put(actions.flush());
  });
}

export const sagas = [
  init,
  handlePortfolioFilterChange,
  flush,
  loadDefaultFilters,
  saveDefaultFilters,
  deleteDefaultFilters,
  setActiveStrategy,
  onActiveStrategyChange,
];
