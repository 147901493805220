import { takeEvery, call, put } from 'redux-saga/effects';

import { AsyncStorage } from '@formue-app/core';

import { actions as appActions } from '../../app/actions';
import { actions } from './actions';

const STORAGE_KEY = 'dismissedAppPromotion';

function* hydrateDismissedAppPromotion() {
  yield takeEvery(appActions.init, function* () {
    const dismissedAppPromotion = yield call(AsyncStorage.getItem, STORAGE_KEY);

    if (dismissedAppPromotion) {
      yield put(actions.setDismissedAppPromotion(dismissedAppPromotion));
    }
  });
}

function* dismissAppPromotion() {
  yield takeEvery(actions.dismissAppPromotion, function* ({ payload }) {
    yield call(AsyncStorage.setItem, STORAGE_KEY, payload);
    yield put(actions.setDismissedAppPromotion(payload));
  });
}

export const sagas = [dismissAppPromotion, hydrateDismissedAppPromotion];
