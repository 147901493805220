import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Switch, useLocation } from 'react-router-dom';

import { BasePage } from '../base/BasePage';
import { ExtendedReportingHeader } from '../../components/extendedReporting/ExtendedReportingHeader';
import { ExtendedReportingMenu } from '../../components/navigation/ExtendedReportingMenu';
import { RouteWithSubRoutes } from '../../components/router';
import { backgroundMain } from '../../constants/colors';
import {
  longAnimationTime,
  mediumAnimationTime,
} from '../../constants/animation';
import { FiltersButton } from '../../components/extendedReporting/FiltersButton';
import { ExtendedReportingFilters } from '../../components/extendedReporting/filters/ExtendedReportingFilters';
import {
  useQlikApp,
  useQlikSelections,
  useQlikActiveFilters,
  useQlikBookmarks,
} from '@formue-app/core';
import { FilterBadges } from '../../components/extendedReporting/filters/FilterBadges';
import { ModalSaveFilters } from '../../components/extendedReporting/filters/ModalSaveFilters';

const PageWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: ${backgroundMain};
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  transition: all ${longAnimationTime};
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  ${(props) =>
    props.filtersOpen &&
    css`
      max-width: calc(100% - 324px);
    `}
`;

const StyledFilterBadges = styled(FilterBadges)`
  position: absolute;
  right: 115px;
  top: 71px;
  paddingleft: 150px;
  z-index: 5;
  transition: all ${mediumAnimationTime};
  translate: 10%;
  opacity: 0;

  ${(props) =>
    props.open &&
    `
    translate: 0;
    opacity: 1;
  `};
`;

export const ExtendedReportingPage = (props) => {
  const { routes } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const location = useLocation();

  // Check if we are on the root or child page by looking for two forward slashes
  // in "pathname".
  const isRootPage = !location.pathname.match(/([^/]*\/){2}/);

  const [app, qlik] = useQlikApp();
  const [selections, onClear] = useQlikSelections(app);
  const activeFilters = useQlikActiveFilters(app, selections);
  const [_, createBookmark] = useQlikBookmarks(app);

  const toggleFiltersPanel = () => {
    // Dispatch the resizse event to force the qlik visualizations to redraw
    // we want this to happen size the containers they have been embedded into
    // changes when we open the filters panel.
    window.dispatchEvent(new Event('resize'));
    setFiltersOpen(!filtersOpen);
  };

  useEffect(() => {
    return () => {
      // Make sure we close any connection to qlik when we unmount this
      // component. It will prevent the websocket from running until the
      // the browser closes.
      if (qlik) {
        qlik.close();
      }
    };
  }, []);

  return (
    <BasePage {...props}>
      <PageWrapper>
        <ExtendedReportingHeader
          menuOpenCallback={(open) => {
            setMenuOpen(open);
          }}
          menuOpen={menuOpen}
        />

        <ExtendedReportingMenu
          open={menuOpen}
          routes={routes}
          onClose={() => {
            setMenuOpen(false);
          }}
        />

        {location.pathname !== '/extended-reporting' && (
          <FiltersButton
            onClick={toggleFiltersPanel}
            active={filtersOpen ? 1 : 0}
            style={{ position: 'absolute', right: 24, top: 86 }}
          />
        )}
        <StyledFilterBadges
          activeFilters={activeFilters}
          open={true}
          onClear={onClear}
          onSave={() => {
            setModalIsOpen(!modalIsOpen);
          }}
          style={isRootPage ? { display: 'none' } : {}}
        />

        <ContentWrapper filtersOpen={filtersOpen}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} app={app} />
            ))}
          </Switch>
        </ContentWrapper>

        <ExtendedReportingFilters
          open={filtersOpen}
          app={app}
          activeFilters={activeFilters}
          selections={selections}
          onClear={onClear}
        />
      </PageWrapper>

      <ModalSaveFilters
        isOpen={modalIsOpen}
        createBookmark={createBookmark}
        onRequestClose={() => setModalIsOpen(false)}
      />
    </BasePage>
  );
};
