import React from 'react';
import styled from 'styled-components';

import { SPACING_12, SPACING_16 } from '../../../../constants/spacing';
import { Paragraph, ParagraphXSmall } from '../../../texts';

import { ProfileIcon } from '../../../common/ProfileIcon';

import sustainabilityAdvisorImageUrl from '../../../../assets/images/sustainability/sustainability-advisor.jpg';

const Container = styled.div`
  display: flex;
  gap: ${SPACING_12};
  padding-top: ${SPACING_16};
`;

const Name = styled(Paragraph)`
  font-weight: 500;
`;

export const SustainabilityAdvisor = (props) => {
  return (
    <Container>
      <ProfileIcon url={sustainabilityAdvisorImageUrl} size={40} />
      <div>
        <Name>Philip Mitchell</Name>
        <ParagraphXSmall>Senior Sustainability Advisor </ParagraphXSmall>
      </div>
    </Container>
  );
};
