import { createGlobalStyle } from 'styled-components';

// Fetched from here: https://dev.to/hankchizljaw/a-modern-css-reset-6p3
export const ResetStyles = createGlobalStyle`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  /* ul[class],
  ol[class] {
    padding: 0;
  } */

  /* ul[class],
  ol[class] {
    margin: 0;
  } */

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  html, body, #root {
    height: 100%;
    overscroll-behavior-y: none;
  }

  #root > div {
    height: 100%;
  }

  .noScrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }

  /* Set core body defaults & fix the fonts rendering */
  body {
    scroll-behavior: smooth;
    line-height: 1.5;
    text-wrap: balance;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  /* article > * + * {
    margin-top: 1em;
  } */

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font-size: inherit;
    line-height: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  @media only print {
    body {
      overflow:visible;
    }
    @page {
      margin: 0;
      padding: 0;
      size: landscape;
    }
  }

  .printBackground {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }

  .printOnly {
    display: none;

    @media only print {
      display: block;
    }
  }

  // Note that the animation propperty still runs
  .notransition *,
  .notransition *:after,
  .notransition *:before {
    transition: none !important;
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }

  svg, img {
    vertical-align: bottom;
  }

`;
