import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { GridElement } from '../layout/GridElement';
import { bodyFontStack } from '../../fonts';
import {
  SPACING_32,
  SPACING_24,
  SPACING_8,
  SPACING_16,
  SPACING_12,
} from '../../constants/spacing';
import {
  textMain,
  backgroundWhite,
  accent,
  hoverBoxShadowLvl2,
} from '../../constants/colors';
import {
  paragraphSmallLineHeight,
  paragraphSmallSize,
} from '../../constants/text';

import {
  H3,
  h3Style,
  h4Style,
  h5Style,
  ParagraphSmall,
  paragraphSmallStyle,
  paragraphStyle,
} from '../texts';
import {
  mobileCondition,
  notMobileCondition,
  notPrintCondition,
  printCondition,
} from '../../constants/media';
import { InfiniteScroll } from '../common/InfiniteScroll';
import { SpriteIcon } from '../common/SpriteIcon';

import { easeInOut, shortAnimationTime } from '../../constants/animation';

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
  font-family: ${bodyFontStack};
  color: ${textMain};
`;

export const StyledRow = styled.tr`
  background: ${backgroundWhite};
  border-radius: ${SPACING_8};
  border-bottom-right-radius: ${(props) => (props.open ? 0 : '5px')};
  border-bottom-left-radius: ${(props) => (props.open ? 0 : '5px')};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  vertical-align: top;
  z-index: 2;
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  td:last-child {
    border-bottom-right-radius: ${(props) => (props.open ? 0 : '5px')};
  }

  td:first-child {
    border-bottom-left-radius: ${(props) => (props.open ? 0 : '5px')};
  }

  &:hover {
    ${(props) =>
      props.hasSubItem &&
      css`
        box-shadow: ${hoverBoxShadowLvl2};
      `}
  }

  ${(props) =>
    props.isSubHeading &&
    css`
      @media ${printCondition} {
        & + tr th {
          padding-top: ${SPACING_12} !important;
        }
      }
    `}
`;

export const StyledSubRowWrapper = styled(StyledRow)`
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  display: ${(props) => (props.open ? 'table-row' : 'none')};
  z-index: 1;

  td:first-child {
    border-top-left-radius: ${(props) => (props.open ? 0 : '5px')};
    border-bottom-left-radius: ${(props) => (props.open ? 0 : '5px')};
  }

  td:last-child {
    border-top-right-radius: ${(props) => (props.open ? 0 : '5px')};
    border-bottom-right-radius: ${(props) => (props.open ? 0 : '5px')};
  }

  // Yeah, absolutly love this selector, not confusing at all…
  td table tbody tr:nth-child(odd) {
    background: ${accent.sand150};
  }

  td table tbody tr {
    border-bottom: 4px solid red;
  }

  @media ${printCondition} {
    display: table-row !important;

    & table {
      border: 1px solid ${accent.sand3};
      margin-bottom: ${SPACING_32} !important;
      margin-top: -7px !important;
    }
  }
`;

export const StyledSubRow = styled(StyledRow)`
  padding: 0;

  &:last-child {
    td:first-child {
      border-bottom-left-radius: 5px;
    }

    td:last-child {
      border-bottom-right-radius: 5px;
    }
  }

  @media ${printCondition} {
    background: none;
  }
`;

export const StyledCell = styled.td`
  padding: 18px ${SPACING_8};

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-left: ${SPACING_24};
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-right: ${SPACING_24};
  }
`;

export const StyledSubHeadingCell = styled(StyledCell)`
  border-bottom: 2px solid ${accent.sand150};
  page-break-after: avoid;
  margin-bottom: ${SPACING_16};

  padding-left: ${SPACING_16} !important;
  padding-right: ${SPACING_16} !important;
  padding-bottom: ${SPACING_8};
  padding-top: ${SPACING_32};
  background-color: white !important;

  border-radius: 0 !important;

  span {
    ${h3Style}

    @media ${printCondition} {
      ${h5Style}
      font-size: 12px;
    }
  }
`;

export const StyledSubCell = styled(StyledCell)`
  padding: 16px;

  span {
    font-size: ${paragraphSmallSize};
    line-height: ${paragraphSmallLineHeight};
    font-weight: 400;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
`;

const CenterContent = styled.span`
  ${h4Style}

  display: flex;
  align-items: center;
  height: 100%;
  text-align: ${({ type }) => (type === 'number' ? 'right' : 'left')};

  @media ${notMobileCondition} {
    justify-content: ${({ type }) =>
      type === 'number' ? 'flex-end' : 'flex-start'};
  }

  @media ${mobileCondition} {
    flex-direction: column;
    align-items: ${({ type }) =>
      type === 'number' ? 'flex-end' : 'flex-start'};
  }

  @media ${printCondition} {
    ${paragraphSmallStyle}
  }
`;

export const StyledHeaderCell = styled.th`
  font-size: ${paragraphSmallSize};
  font-weight: 500;
  text-align: left;
  padding: ${SPACING_8};
  cursor: ${(props) => (props.sortable ? 'pointer' : 'default')};
  text-align: ${({ type }) => (type === 'number' ? 'right' : 'left')};
  text-decoration: ${({ direction }) => (!!direction ? 'underline' : 'none')};

  @media ${notMobileCondition} {
    justify-content: ${({ type }) =>
      type === 'number' ? 'flex-end' : 'flex-start'};
  }

  &:first-child {
    padding-left: ${SPACING_24};
  }

  &:last-child {
    padding-right: ${SPACING_24};
  }

  @media ${mobileCondition} {
    display: none;
  }
`;

const StyledHeader = styled.thead`
  ${(props) =>
    props.stickyHeader &&
    css`
      &:first-child {
        @media ${notPrintCondition} {
          background: ${backgroundWhite};
          position: sticky;
          top: 0;
        }
      }
    `};
`;

const InfoWrapper = styled.span`
  position: relative;
  margin-left: 8px;
`;

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null && sortConfig.sortable) {
      sortableItems.sort((a, b) => {
        // TODO: sort according to type of input. (bool, number, locale-specific string etc.)
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.ascending ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.ascending ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const onSort = sortConfig.sortable
    ? (key) => {
        let ascending = false;
        if (sortConfig.key === key) {
          ascending = !sortConfig.ascending;
        }

        setSortConfig({ ...sortConfig, key, ascending });
      }
    : undefined;

  return { items: sortedItems, onSort, sortConfig };
};

const TableListHeader = (props) => {
  const { columns, onSort, getDirection, stickyHeader } = props;
  return (
    <StyledHeader stickyHeader={stickyHeader}>
      <tr>
        {columns.map(
          ({
            key,
            label,
            subLabel,
            infoFunction,
            headerComponent,
            ...rest
          }) => {
            // Allow column definition to override which component we use to render a cell
            let CellComponent = headerComponent || StyledHeaderCell;

            return (
              <CellComponent
                key={key}
                onClick={() => (onSort ? onSort(key) : undefined)}
                direction={onSort ? getDirection(key) : undefined}
                {...rest}
              >
                {label}
                {subLabel ? <ParagraphSmall>{subLabel}</ParagraphSmall> : null}
                {infoFunction && (
                  <InfoWrapper onClick={() => infoFunction()}>
                    <SpriteIcon id="Info" size={SPACING_16} />
                  </InfoWrapper>
                )}
              </CellComponent>
            );
          }
        )}
      </tr>
    </StyledHeader>
  );
};

const TableListContent = (props) => {
  const {
    columns,
    rows,
    openItems,
    rowComponent,
    subRowComponent,
    keyExtractor,
    clickCallback,
    stickyHeader = false,
  } = props;

  const renderColumns = (columns, item, rowIndex, subItem = false) => {
    return columns.map((column, columnIndex) => {
      const {
        key,
        label,
        render,
        primary,
        component,
        subCellComponent,
        width,
        type,
        ...rest
      } = column;
      // Allow column definition to override which component we use to render a cell
      let CellComponent = component || StyledCell;

      if (subItem) {
        CellComponent = subCellComponent || StyledSubCell;
      }

      return (
        <CellComponent
          key={`${key}-${rowIndex}`}
          primary={primary}
          label={label}
          item={item}
          as={subItem ? 'td' : 'th'}
          width={width}
          {...rest}
        >
          <CenterContent type={type}>
            {render ? render({ ...item, subItem }, rowIndex) : item[key]}
          </CenterContent>
        </CellComponent>
      );
    });
  };

  const renderSubHeading = (columns, item, rowIndex) => {
    return (
      <StyledSubHeadingCell
        key={`heading-${rowIndex}`}
        primary={false}
        item={item}
        as="th"
        width="100%"
        colSpan={columns.length + 1}
      >
        <CenterContent>
          <span>{item.title}</span>
        </CenterContent>
      </StyledSubHeadingCell>
    );
  };

  const RowComponent = rowComponent ? rowComponent : StyledRow;
  const SubRowComponent = subRowComponent ? subRowComponent : StyledSubRow;

  return rows.map((item, index) => (
    <React.Fragment key={`row-${keyExtractor(item, index)}`}>
      <RowComponent
        onClick={
          clickCallback
            ? (e) => {
                e.preventDefault();
                clickCallback(item);
              }
            : null
        }
        index={index}
        open={openItems ? openItems.includes(item.id) : false}
        stickyHeader={stickyHeader}
        item={item}
        hasSubItem={!!item.subItems}
        isSubHeading={item.subHeading}
        className={`${item.className} ${item.subHeading ? 'subHeading' : ''}`}
      >
        {item.subHeading
          ? renderSubHeading(columns, item, index)
          : renderColumns(columns, item, index)}
      </RowComponent>
      {!!item.subItems && (
        <StyledSubRowWrapper open={openItems.includes(item.id)}>
          <StyledCell colSpan={columns.length + 1} style={{ padding: 0 }}>
            <StyledTable
              style={{ borderSpacing: 0, marginBottom: 6, marginTop: -2 }}
            >
              <tbody>
                {item.subItems.map((subitem, index) => (
                  <SubRowComponent
                    key={`subitem-${subitem.id}-${index}`}
                    style={{ boxShadow: 'none' }}
                    item={subitem}
                  >
                    {renderColumns(columns, subitem, index, true)}
                  </SubRowComponent>
                ))}
              </tbody>
            </StyledTable>
          </StyledCell>
        </StyledSubRowWrapper>
      )}
    </React.Fragment>
  ));
};

const useSortKey = (columns) => {
  let defaultSort = columns.find((x) => x.defaultSort);
  if (!defaultSort) defaultSort = columns.find((x) => x.primary);
  return defaultSort ? defaultSort.key : columns[0].key;
};

export const TableList = (props) => {
  const {
    rows,
    tableComponent,
    sortable = true,
    infiniteScroll = false,
    perPage = 6,
    ...rest
  } = props;

  // Determine if we have no rows
  const listIsEmpty = rows.length === 0;

  const {
    id,
    className,
    columns,
    ListEmptyComponent,
    showHeader = true,
    style,
  } = rest;

  let sortKey = useSortKey(columns);

  const {
    items: sortedItems,
    onSort,
    sortConfig,
  } = useSortableData(rows, {
    key: sortKey,
    ascending: false,
    sortable,
  });

  // If infiniteScroll is enabled we limit the number of items to show for each load sycle
  // If infiniteScroll is disabled we load every item at once setting item length as numbers pr page
  const numberOfItemsPerPage = infiniteScroll ? perPage : sortedItems.length;
  const [numberOfItemsToShow, setNumberOfItemsToShow] =
    useState(numberOfItemsPerPage);

  const items = sortedItems.slice(0, numberOfItemsToShow);

  const loadItems = (page) => {
    setNumberOfItemsToShow((page + 1) * numberOfItemsPerPage);
  };

  const getDirection = (name) => {
    if (!sortConfig) {
      return;
    }

    return sortConfig.key === name
      ? sortConfig.ascending
        ? 'ascending'
        : 'descending'
      : undefined;
  };

  const TableComponent = tableComponent ? tableComponent : StyledTable;

  return (
    <GridElement id={id} className={className} columns={12} style={style}>
      <InfiniteScroll next={loadItems}>
        <TableComponent {...rest}>
          {showHeader ? (
            <TableListHeader
              key="header"
              onSort={onSort}
              getDirection={getDirection}
              {...props}
            />
          ) : null}
          {listIsEmpty ? (
            ListEmptyComponent
          ) : (
            <tbody>
              <TableListContent rows={items} {...rest} />
            </tbody>
          )}
        </TableComponent>
      </InfiniteScroll>
    </GridElement>
  );
};
