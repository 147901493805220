import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { SPACING_24 } from '../../constants/spacing';
import { mobileCondition, notMobileCondition } from '../../constants/media';
import { accent, blackTranslucent } from '../../constants/colors';
import { menuMaxWidth, menuClosedWidth } from '../../constants/grid';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

import { actions as navigationActions } from '../../store/ui/navigation';

import { dismissedAppPromotionSelector } from '../../store/ui/appPromotion/selectors';

const StyledMainMenuWrapper = styled.nav`
  background: ${accent.ocean4};
  width: ${menuMaxWidth}px;
  position: relative;
  z-index: 1;

  ${(props) =>
    !props.open &&
    `
    width: ${menuClosedWidth}px;`}

  @media ${mobileCondition} {
    ${(props) => props.showAppPromitionBanner && `top: 80px;`}

    background: none;
    width: 100%;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
`;

const StyledMainMenuInner = styled.div`
  @media ${notMobileCondition} {
    width: ${menuMaxWidth}px;
    position: fixed;
    height: 100%;
    z-index: 1;

    ${(props) =>
      !props.open &&
      `
      width: ${menuClosedWidth}px;`}
  }
`;

const StyledArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccddef;
  border-radius: 50%;
  cursor: pointer;
  height: ${SPACING_24};
  position: absolute;
  right: -12px;
  top: calc(100vh - 40px);
  width: ${SPACING_24};
  box-shadow: 0 4px 6px ${blackTranslucent(0.08)};

  @media ${mobileCondition} {
    display: none;
  }

  svg {
    left: -1px;
    position: relative;
  }

  ${(props) =>
    !props.open &&
    `
    svg {
      left: 0px;
      rotate: 180deg;
    }`}
`;

export const MainMenuWrapper = (props) => {
  const dispatch = useDispatch();
  const { children, open } = props;

  const showAppPromitionBanner = useSelector(dismissedAppPromotionSelector);

  return (
    <StyledMainMenuWrapper
      showAppPromitionBanner={!showAppPromitionBanner}
      {...props}
    >
      <StyledMainMenuInner {...props}>
        <StyledArrow
          onClick={() => dispatch(navigationActions.toggleMainMenu())}
          open={open}
        >
          <SpriteIconFunctional size={14} id="Simple arrow" direction="Left" />
        </StyledArrow>

        {children}
      </StyledMainMenuInner>
    </StyledMainMenuWrapper>
  );
};
