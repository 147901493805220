import React from 'react';
import { useTranslation } from 'react-i18next';

import { GridElement } from '../../layout';
import { H1, H4, Paragraph } from '../../texts';
import { SPACING_32, SPACING_64, SPACING_8 } from '../../../constants/spacing';
import { Logo } from '../../common/Logo';
import styled from 'styled-components';

const Wrapper = styled(GridElement)`
  @page:last {
    margin: 0 !important;
    padding: 0 !important;
  }

  padding: ${SPACING_64};

  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const PeriodicReportingEndPageDisclaimer = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper columns={12} className="printBackground printOnly">
      <Content>
        <H1>{t('portfolio:periodicReporting:endPageDisclaimer.title')}</H1>

        <H4 style={{ marginTop: SPACING_64, marginBottom: SPACING_8 }}>
          {t('portfolio:periodicReporting:endPageDisclaimer.generalTitle')}
        </H4>
        <Paragraph>
          {t('portfolio:periodicReporting:endPageDisclaimer.generalContent')}
        </Paragraph>
        <H4 style={{ marginTop: SPACING_32, marginBottom: SPACING_8 }}>
          {t('portfolio:periodicReporting:endPageDisclaimer.priceUpdatesTitle')}
        </H4>
        <Paragraph>
          {t(
            'portfolio:periodicReporting:endPageDisclaimer.priceUpdatesContent'
          )}
        </Paragraph>
        <H4 style={{ marginTop: SPACING_32, marginBottom: SPACING_8 }}>
          {t(
            'portfolio:periodicReporting:endPageDisclaimer.investmentRiskTitle'
          )}
        </H4>
        <Paragraph>
          {t(
            'portfolio:periodicReporting:endPageDisclaimer.investmentRiskContent'
          )}
        </Paragraph>
        <H4 style={{ marginTop: SPACING_32, marginBottom: SPACING_8 }}>
          {t(
            'portfolio:periodicReporting:endPageDisclaimer.confidentialityTitle'
          )}
        </H4>
        <Paragraph>
          {t(
            'portfolio:periodicReporting:endPageDisclaimer.confidentialityContent'
          )}
        </Paragraph>

        <Logo height={15} style={{ marginTop: 'auto' }} />
      </Content>
    </Wrapper>
  );
};

export default PeriodicReportingEndPageDisclaimer;
